<template>

    <v-container v-if="desserts" class="view-info">
      <v-chip class="mb-3" v-if="desserts.fechainiprevista" label >
        <v-icon left>av_timer</v-icon>{{ formatDate( desserts.fechainiprevista) }} <span v-if="desserts.fechafinprevista">&nbsp;&nbsp;&nbsp;->&nbsp;&nbsp;&nbsp;</span> {{formatDate(desserts.fechafinprevista)}}
      </v-chip>

      <h3>{{desserts.tipo}}</h3>

      <div class="text">
            <div class="mt-2"><b>CLIENTE: </b>{{desserts.nombrecliente}}</div>
          <div v-if="desserts.codcliente!=desserts.codclientefinal"><b>CLIENTE FINAL: </b>{{desserts.nombreclientefinal}}</div>

          <p class="desccorta" text-color="white">{{desserts.descripcioncorta}}</p>
  
          <div v-if="desserts.descripcionlarga" class="desclarga" stylw='font-size:8px;' text-color="white"> {{desserts.descripcionlarga}} </div>

          <!-- <div v-if="desserts.observaciones"><b>OBSERVACIONES: </b>{{desserts.observaciones}}</div> -->
      </div>
      

      <div class="pryectos" v-if="desserts.codproyecto">
          <div class="proyecto">  <h3>Proyecto: {{desserts.codproyecto}} </h3> </div>
          <div v-if="desserts.descripcion" class="presupuestos"> Descripción: {{desserts.descripcion}}</div>

      </div>

      <div class="presupuestos" v-if="desserts.codigo">
          <div class="presupuestos">  <h3>Presupuesto: {{desserts.codigo}} </h3> </div>
          <div v-if="desserts.titulo" class="presupuestos"> Titulo: {{desserts.titulo}}</div>
          <!-- <div v-if="desserts.notas" class="presupuestos"> Notas: {{desserts.notas}}</div> -->
      </div>


      <v-card style="padding:10px 20px 20px 20px;margin-top: 20px;">
        <h3 style='font-weight: normal;'>OBSERVACIONES</h3>
        <v-textarea
              clearable
              v-model="desserts.observaciones"
              auto-grow
              rows="2"
              clear-icon="cancel"
              class="uppercase"
        ></v-textarea>
        <button @click="guardarobservaciones()" class="buttoncambiarestado"><v-icon class='icon' style="">save</v-icon>Guardar observaciones</button>

      </v-card>

      <v-card style="padding:10px 20px 20px 20px;margin-top: 20px;">
            <!-- <v-textarea
              clearable
              v-model="observaciones"
              auto-grow
              rows="2"
              clear-icon="cancel"
              v-bind:label="$t('prealbaran_observaciones')"
              onkeyup="javascript:this.value=this.value.toUpperCase();"
            ></v-textarea> -->

        <div class="optionsfirma">
          <v-switch v-model="checkboxfirmarprealbaran" :label="`Firmar albarán de finalización`" hide-details :disabled="checkboxfirmarprealbaran_disabled"></v-switch>
          <v-switch v-model="checkboxenviaremail" :label="`Enviar email`" hide-details :disabled="checkboxenviaremail_disabled"></v-switch>
          <!--<v-text-field v-if="checkboxenviaremail" class="textemail" v-model="desserts.emails" ref="email" label="Email" outlined clearable dense hide-details ></v-text-field>-->
          <AddEmail v-if="checkboxenviaremail" v-bind:objects="email_list" />
        </div>

        <div v-if="checkboxfirmarprealbaran"><Firma v-bind:text="'FIRMAR Y CREAR ALBARÁN DE FINALIZACIÓN'" /></div>
        <div v-else> <button @click="button_crearalbaran()" class="buttoncreate"><v-icon class='icon' style="">create</v-icon>CREAR ALBARÁN DE FINALIZACIÓN</button></div>
      </v-card>



      <button @click="dialog_finalizarparte=true;" class="buttoncambiarestado"><v-icon class='icon' style="">lock</v-icon>FINALIZAR ORDEN DE TRABAJO</button>





    <v-toolbar flat v-if="dialog_finalizarparte">
      <v-dialog v-model="dialog_finalizarparte" persistent max-width="500px">
        <v-card style="background:#ffffff;">
          <v-card-title>
            <span class="headline">Finalizar Orden de trabajo</span>
          </v-card-title>
          
          <v-card-text>
            ¡ATENCIÓN!<br> Si finalizas <b>no podrás ver ni modificar la orden</b> de trabajo desde la App. 
            <br><br>
            ¿Estás seguro que desea finalizar la orden?
            
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click="dialog_finalizarparte=false;">Cancelar</v-btn>
            <v-btn color="#555555" text @click="finalizarparte(prealbaranlinea)">FINALIZAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>






      <v-dialog v-model="dialogVerificacionAlbaran" max-width="400">
        <v-card>
        <v-card-title class="headline">Crear Albarán</v-card-title>
          <v-card-text>
            <div class="mb-1" style="color:#bd0808;" v-if="checkboxenviaremail && email_envio_visible==''"><b>Atención!</b> no se ha especificado <b>email</b>!</div>
            <!-- <div class="mb-1" style="color:#bd0808;" v-if="observaciones==''"><b>Atención!</b> no hay <b>observaciones</b>!</div> -->
            <div class="mb-1" style="color:#bd0808;" v-if="checkboxfirmarprealbaran && firma.nombrefirmante==''"><b>Atención!</b> sin <b>Nombre firmante</b>!</div>
            <div v-if="checkboxenviaremail && email_envio_visible!=''"><b>Enviar a:</b><br> {{email_envio_visible}}</div>
            <div v-if="!checkboxenviaremail && !checkboxfirmarprealbaran">Crear Albarán sin firma y sin enviar por email</div>
          </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click="dialogVerificacionAlbaran=false">Cancelar</v-btn>
            <v-btn color="#555555" text @click="crearalbaranfromprealbaran()">Crear</v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
    </v-container>
</template>

<script>
import { gw } from "@/gw.js";
import Firma from "@/components/Firma";
import AddEmail from "@/components/AddEmail";

export default {
  mixins: [gw],
  name: "Info",
  props: {
    desserts: Object
  },
  components: {
    Firma,
    AddEmail
  },
  data: () => ({
    dialogVerificacionAlbaran: false,
    checkboxfirmarprealbaran: true,
    checkboxfirmarprealbaran_disabled: false,
    checkboxenviaremail: true,
    checkboxenviaremail_disabled: false,
    email_list: [],
    email_envio_visible: "",
    email: "",
    observaciones:"",
    dialog_finalizarparte:false
  }),
  computed: {
    
  }, 
  watch: {
   firma(){
      this.button_crearalbaran();
      //this.dialogVerificacionAlbaran = true;
      //this.crearalbaranfromprealbaran();
    },
    desserts() {
      if (this.desserts != null) {
        this.email_list = [
          // ...this.email_list,
          ...(this.desserts.emails || []).map(text => ({
            text,
            selected: false
          })),
          ...(this.desserts.emailsalbaran || []).map(text => ({
            text,
            selected: true
          }))
        ]
      }
    }
  }, 
  mounted() {
    window.info = this;
    console.log(this.desserts);
  },
  methods: {
    button_crearalbaran(){
      if(this.checkboxenviaremail){//Enviar el albaran firmado, acabado de crear o desde albará indicando el codigo (mismo codigo en prealbaran y albaran)
        //verificamos email o emails
        var emails = [];

        this.email_list.forEach(e => {
          if(e.selected){
              emails.push(e.text);
          }
          
        });
        if(emails.length==0){
          this.toasted("Debe indicar almenos un Email", "error", "error");
          this.dialogVerificacionAlbaran = false;
          //this.$refs.email.focus();
          return true;
        }
        for (let i = 0; i < emails.length; i++){
          if(emails[i]!="" && !this.validarEmail(emails[i])){
            //Si entramos aquí es que hay algún email que es incorrecto
            this.toasted("Hay un Email incorrecto, deseleccionelo y vuelva a intertarlo", "error", "error");
            this.dialogVerificacionAlbaran = false;
            //this.$refs.email.focus();
            return true;
          }
        }
        this.email_envio_visible = emails.join(', ');
      }
      else{
         this.email_envio_visible = '';
      }

      this.dialogVerificacionAlbaran=true;
    },
    crearalbaranfromprealbaran(item){
      var filter = {};
      var data = []; data[0] = {};

      //data[0]["prealbaran"] = false; //Crear prealbarán vacio, Si se envía a true no importa nada más.

      //filter["codigo"] = this._routerRoot._route.params.codigo;
      //filter["idprealbaran"] = this.prealbaran.idprealbaran;
      filter["codorden"] = this.desserts.codorden;
      filter["codcliente"] = this.desserts.codcliente;
      //data[0]["codigo"] = this._routerRoot._route.params.codigo;
      //data[0]["idprealbaran"] = this.prealbaran.idprealbaran;
      data[0]["codorden"] = this.desserts.codorden;
      data[0]["codcliente"] = this.desserts.codcliente;
      //data[0]["observaciones"] = this.desserts.observaciones;

      //data[0]["albaranar"] = true; //Crear el albaran a partir del prealbaran

      if(this.checkboxenviaremail){//Enviar el albaran firmado, acabado de crear o desde albará indicando el codigo (mismo codigo en prealbaran y albaran)
        //verificamos email o emails
        var emails = [];

        this.email_list.forEach(e => {
          if(e.selected){
              emails.push(e.text);
          }
          
        });
        if(emails.length==0){
          this.toasted("Debe indicar almenos un Email", "error", "error");
          this.dialogVerificacionAlbaran = false;
          //this.$refs.email.focus();
          return true;
        }
        for (let i = 0; i < emails.length; i++){
          if(emails[i]!="" && !this.validarEmail(emails[i])){
            //Si entramos aquí es que hay algún email que es incorrecto
            this.toasted("Hay un Email incorrecto, deseleccionelo y vuelva a intertarlo", "error", "error");
            this.dialogVerificacionAlbaran = false;
            //this.$refs.email.focus();
            return true;
          }
        }
        data[0]["email_envio"] = emails.join(';');
      }
      else{
        data[0]["email_envio"] = '';
      }

      if(this.checkboxfirmarprealbaran){
        data[0]["firmadocli"] = true;
        data[0]["firma_dataURL"] = this.firma.dataURL;
        data[0]["firma_nombrefirmante"] = this.firma.nombrefirmante;
      }
      else{
        data[0]["firmadocli"] = false;
        data[0]["firma_dataURL"] = '';
        data[0]["firma_nombrefirmante"] = '';
      }
      
      let _this = this;
      this.api("/peticion/eneboo/ordenesfirmar", {'ordenes':{'filter':filter,'data':data}}, true).then(
        resp => {
          if (resp.success == 1) {//Me devuelve algo, Peeero puede devolver un error..
            //Todo Ok
            _this.setMessage({
              active:true,
              icon:'check_circle',
              coloricon:'#1ca020',
              text:'Se ha creado el Albarán con éxito',
              buttons:[
              {
                text:'Menú principal',
                to:'/'
              },
              {
                text:'Aceptar',
                to:''
              }]
            });
            _this.to('/ordentrabajo/'+_this.desserts.codorden,'rerender');
          }else if (resp.success == 2){
            _this.toasted(resp.msg,"error","error");
          }else {
            //error
            console.log(resp);
            _this.toasted("No se ha podido crear el albarán", "error", "error");

          }
          // if(resp.success == 1 )  _this.toasted("Se ha " + texto + " al carro corectamente", "success", 'shopping_cart');
          // else _this.toasted("No se ha podido al carro", "error", 'shopping_cart');
        },
        err => {
          _this.toasted("No se ha podido crear el albarán. ERR!", "error", "error");
          console.log("Error no controlado API");
          console.log(err);
        }
      );
    },
    guardarobservaciones() {
      let data = {
        observaciones: this.desserts.observaciones,
        codorden: this.desserts.codorden,
        action:'guardarobservaciones'
      };
      
      let _this = this;
      this.api("/peticion/ordentrabajo", data, true).then(
        resp => {
          if (resp.success == 1){
            _this.toasted(
              "Actualizado correctamente",
              "success",
              "check"
            );
          }else if (resp.success == 2){
            _this.toasted(resp.msg,"error","error");
          }else{
            _this.toasted(
              "Error. No se ha podido guardar",
              "error",
              "error"
            );
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    finalizarparte(){
      let data = {
        codorden: this.desserts.codorden,
        action:'finalizarparte'
      };
      
      let _this = this;
      this.api("/peticion/ordentrabajo", data, true).then(
        resp => {
          if (resp.success == 1){
            _this.toasted(
              "Parte finalizado",
              "success",
              "check"
            );
            this.to('/ordenestrabajo/','rerender');

          }else if (resp.success == 2){
            _this.toasted(resp.msg,"error","error");
          }else{
            _this.toasted(
              "Error. No se ha podido finalizar el parte",
              "error",
              "error"
            );
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }
};
</script>
