import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"view-ordentrabajo",attrs:{"grid-list-md":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VChip,{staticClass:"ml-0 mr-3 mb-3",attrs:{"label":"","color":"#c72118","text-color":"white"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("label")]),_vm._v(_vm._s(_vm.orden.codorden)+" ")],1),_c('div',[(_vm.bottomNav.itemactive=='info')?_c('Info',{attrs:{"desserts":_vm.orden}}):(_vm.bottomNav.itemactive=='albaranes')?_c('Albaranes',{attrs:{"desserts":_vm.albaranes}}):(_vm.bottomNav.itemactive=='prealbaranes')?_c('PreAlbaranes',{attrs:{"desserts":_vm.prealbaranes}}):(_vm.bottomNav.itemactive=='material')?_c('MaterialReservado',{attrs:{"desserts":_vm.material}}):(_vm.bottomNav.itemactive=='adjuntos')?_c('Adjuntos',{attrs:{"desserts":_vm.adjuntos}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }