<template>
  <v-container>
      <v-card-title>
        <v-spacer class="titulo-combi-search">MATERIAL RESERVADO</v-spacer>
        <v-text-field  v-if="desserts" style="max-width:500px;width: 100%;"
          v-model="search"
          append-icon="search"
          label="Buscar..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      

          <v-toolbar flat color="white" >
            <v-toolbar-title></v-toolbar-title>
              <v-switch
              v-model="filtrar_material"
              label="Solo pendientes"
              color="primary"
              hide-details
              @change="setmaterial()"
             ></v-switch>
             <v-spacer></v-spacer>
             <v-dialog v-model="dialog" persistent max-width="500px">
              <template v-if="permitirreservar" v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">{{
                  $t("material_dialog_btn_añadir")
                }}</v-btn>
              </template>
              <v-card style="background:#ffffff;">
                <v-card-title>
                  <span class="headline">Añadir material reservado</span>
                </v-card-title>
                <v-card-text>
                  <v-container v-if="dialog_view == 'search'">
                    <v-text-field v-model="articulo_desc" label="Buscar por referencia o descripción..."></v-text-field>
                    <v-list
                      class="resultado_busqueda"
                      v-if="results_articulo"
                    >
                      <v-list-item
                        v-for="item in results_articulo"
                        :key="item.r+item.n"
                        @click="selecionar_articulo(item)"
                      >
                        <v-list-item-content>
                          <strong style="font-size:11px;">{{ item.r }}</strong>
                          <v-list-item-title v-html="item.d"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-container>
                  <v-container v-if="dialog_view == 'insert'">
                    <div>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="metrialadd.referencia"
                          label="Referencia"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          class="uppercase"
                          v-model="metrialadd.descripcion"
                          label="Descripción"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="metrialadd.canprevista"
                          align-left
                          type="number"
                          label="Cantidad Prevista"
                        ></v-text-field>
                      </v-col>
                    </div>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#555555" text @click="closedialog()">Cancelar</v-btn>
                  <v-btn color="#555555" text @click="dialog_view = 'search'" v-if="dialog_view == 'insert'">Buscar</v-btn>
                  <v-btn color="#555555" text @click="insert(metrialadd)" v-if="dialog_view == 'insert'" >{{ $t("carro_dialog_titulo_btn_linea") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>

      <v-data-table
        v-if="material_mostrar && material_mostrar.length > 0"
        :headers="headers"
        :items="material_mostrar"
        item-key="idmaterial"
        :search="search"
        :footer-props="{'items-per-page-options': [100,1000]}"
        sort-by="nl"
        class="tabla_escritorio pr-3 pl-3"
      >
      
       
        <!-- <template v-slot:item.action="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </template> -->
        <!-- <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>-->
      </v-data-table>

   <v-container v-else>
      <v-card>
        <v-card-text>
          NO HAY MARETIRALES QUE MOSTRAR
        </v-card-text>
      </v-card>
    </v-container>


          <v-select
           v-if="material_mostrar && material_mostrar.length > 0"
          class="tabla_lineas_movil"
          :items="filters"
          item-value="value"
          v-bind:label="filter === '' ? filters[0].text : ''"
          v-model="filter"
          style='background-color:white!important;'
        ></v-select>

         <v-container v-if="prealbaranesfilter && material_mostrar && material_mostrar.length > 0" class="tabla_lineas_movil" grid-list-md>
            <v-layout wrap v-for="item in prealbaranesfilter" :key="'movil_'+item.r+item.n">
              <v-flex>
                <div>
                    <v-chip class="mb-3 mr-3" label v-if="item.r" color="#666666" text-color="white"> 
                      <v-icon left>label</v-icon>{{item.r}}
                    </v-chip>    

                    <v-chip class="mb-3 mr-3" label v-if="item.cp || item.cr || item.cs"> 
                      <v-icon left>pan_tool</v-icon>
                      <label>
                       Prev:<b>{{item.cp}}</b>&nbsp;
                       Res:<b>{{item.cr}}</b>&nbsp;
                       Serv:<b>{{item.cs}}</b>
                       </label>
                    </v-chip> 
                    <v-chip class="mb-3 mr-3" label v-if="item.disp || item.resprov"> 
                      <v-icon left>shopping_cart</v-icon>
                      <label>
                      <label>Disp:<b>{{item.disp}}</b></label>&nbsp;
                      <label v-if="item.resprov">Res prov:<b>{{item.resprov}}</b></label>
                      </label>
                       
                    </v-chip> 
           
                </div>
                <div>
                    <div class="text">         
                      <div v-if="item.d">
                        {{item.d}}
                      </div>
                    </div>
                </div>

              <v-divider style='margin:8px; width:auto;'></v-divider>
            </v-flex>
           
          </v-layout>
        </v-container>



  </v-container>
</template>

<script>
import { gw } from "@/gw.js";
//import axios from 'axios'

export default {
  mixins: [gw],
  name: "MaterialReservado",
  props: ["desserts"],
  data: () => ({
    search: "",
    filtrar_material:true,
    _desserts:null,
    material_mostrar:[],
    permitirreservar:false,
    dialog: false,
    headers: [
      { text: "Ref.", value: "r" },
      { text: "Descripción", value: "d" },
      { text: "Prevista", value: "cp" },
      { text: "Reservada", value: "cr" },
      { text: "Servida", value: "cs" },
      { text: "Disponible", value: "disp" },
      { text: "Reservada Prov.", value: "resprov" }
    ],
    //desserts: [],
    articulo_desc: "",
    articulo_desc_anterior: "",
    results_articulo: [],
    timeout: null,
    dialog_view: "insert",
    metrialadd:{
      canreservada: "0",
      canservida: "0",
      canprevista: "1",
      descripcion: "",
      codorden: "",
      referencia: "",
      observaciones:" "
    },
    filters:[
      { text: "Referencia", value: "r" },
      { text: "Descripción", value: "d" }
    ],
    filter:''
  }),
    computed: {
   prealbaranesfilter() {
     let _this = this;
     if(this._desserts!=null && this._desserts.length>0 && this._desserts[0]['search']==undefined){
        this.material_mostrar.forEach(function(element) {
            element['search'] = Object.values(element).toString().toLowerCase();
        });
      }
      if(this.search.length>2){
        return this.material_mostrar.filter(o => {
          if(o['search'].search(this.search.toLowerCase()) != -1){
            this.closekeyboard();
            return this.material_mostrar;
            }
          })
      }else{
        this.closekeyboard();
        return this.material_mostrar;
      }
    }
  }, 
  mounted(){
    /*this._desserts = this.desserts;
    if(this._desserts!=null && this._desserts.length>0 ){
      this._desserts.forEach(e => {
        if(e.cs < e.cp){
          this.material_mostrar.push(e);
        }
      });
      if(this.material_mostrar.length == 0){
          this.material_mostrar = this._desserts;
          this.filtrar_material = false;
      }
    }*/
  },
    watch: {
    articulo_desc(val) {
      //if (val.length >= 3 &&
      //(this.results_articulo.length==100 || this.results_articulo.length==0)
      //|| this.articulo_desc.length < this.articulo_desc_anterior.length) {

      if (val.length >= 3) {
        this.articulo_desc_anterior = val;
        var _this = this;
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function() {
          _this.buscararticulo(val);
        }, 500);
      } else if (val.length < 3) {
        this.results_articulo = [];
      }
      this.articulo_desc = val;
    },
    desserts() {
      this._desserts = this.desserts;
      if(this._desserts!=null && this._desserts.length>0 ){
        this._desserts.forEach(e => {
          if(e.cs < e.cp){
            this.material_mostrar.push(e);
          }
        });
        if(this.material_mostrar.length == 0){
            this.material_mostrar = this._desserts;
            this.filtrar_material = false;
        }
      }
    },
    filter(val) {
      this.filter = val;
      this.filterlist();
    },
    /*'metrialadd.descripcion': function (val) {
      this.metrialadd.descripcion = val.toUpperCase();
    },*/
  },
  methods: {
    filterlist(){
     if (this._desserts != undefined) {
        let canpo = this.filter;
        return this.material_mostrar.sort((a, b) => (b[canpo] > a[canpo]) ? -1 : 1)
      } 
    },
    setmaterial(){
      if(this.filtrar_material === true){
        this.material_mostrar = [];
        if(this._desserts!=null && this._desserts.length>0){
          this._desserts.forEach(e => {
            if(e.cs < e.cp){
              this.material_mostrar.push(e);
            }
          });
        }
        /* if(this.material_mostrar.length == 0){
          this.material_mostrar = this._desserts;
          this.filtrar_material = false;
        } */
      }else if(this.filtrar_material === false){
        this.material_mostrar = this._desserts;
      }
    },
    closedialog() {
      this.dialog = false;
      this.articulo_desc = "";
      this.articulo_desc_anterior = "";
      this.results_articulo = [];
      this.dialog_view = "insert";
      this.metrialadd.cantidad = "1";
      this.metrialadd.descripcion = "";
      this.metrialadd.idprealbaran = "";
      this.metrialadd.referencia = "";
      this.metrialadd.canreservada = "0";
      this.metrialadd.canservida = "0";
      this.metrialadd.canprevista = "1";
      this.metrialadd.codorden = "";
      this.metrialadd.observaciones = "";
    },
    insert(articulo) {
      if(articulo.obs.toUpperCase==null){articulo.obs.toUpperCase="";}
      if(this.metrialadd.observaciones==null){this.metrialadd.observaciones="";}
      if(this.metrialadd.descripcion==null){this.metrialadd.descripcion="";}
      if(articulo.d==null){articulo.d="";}

      this.metrialadd.referencia = articulo.r === undefined ? this.metrialadd.referencia : articulo.r;
      this.metrialadd.descripcion = articulo.d === undefined ? this.metrialadd.descripcion.toUpperCase() : articulo.d.toUpperCase();
      this.metrialadd.observaciones = articulo.obs === undefined ? this.metrialadd.observaciones.toUpperCase() : articulo.obs.toUpperCase();
      this.metrialadd.codorden = this._routerRoot._route.params.codorden;

      //validaciones
      if(this.metrialadd.referencia=="" || this.metrialadd.referencia==null){
        return false;
      }

      if(this.metrialadd.descripcion==null){this.metrialadd.descripcion="";}
      if(this.metrialadd.observaciones==null){this.metrialadd.observaciones="";}

      var data = {};
      data["coduser"] = this.auth.coduser;
      data["codcliente"] = this.auth.coduser;
      data['observaciones'] = this.metrialadd.observaciones.toUpperCase();
      data["canprevista"] = this.metrialadd.canprevista;
      data["canservida"] = this.metrialadd.canservida;
      data["canreservada"] = this.metrialadd.canreservada;
      data["referencia"] = this.metrialadd.referencia;
      data["descripcion"] = this.metrialadd.descripcion.toUpperCase();
      data['codorden'] = this.metrialadd.codorden;
      this.closedialog(); 
      let _this = this;
      this.api("/peticion/material/insert", data, true).then(
        _resp => {
             _this._desserts = _resp.datos;
             _this.setmaterial();
          return true;
        },
        err => {
          console.log(err);
          return false;
        }
      );
    },
    selecionar_articulo(articulo){
      if(articulo.obs==null){articulo.obs="";}
      if(this.metrialadd.observaciones==null){this.metrialadd.observaciones="";}
      if(this.metrialadd.descripcion==null){this.metrialadd.descripcion="";}
      if(articulo.d==null){articulo.d="";}

      this.metrialadd.referencia = articulo.r === undefined ? this.metrialadd.referencia : articulo.r;
      this.metrialadd.descripcion = articulo.d === undefined ? this.metrialadd.descripcion.toUpperCase() : articulo.d.toUpperCase();
      this.metrialadd.observaciones = articulo.obs === undefined ? this.metrialadd.observaciones.toUpperCase() : articulo.obs.toUpperCase();
      this.metrialadd.codorden = this._desserts[0].codorden;
      this.articulo_desc = "";
      this.articulo_desc_anterior = "";
      this.results_articulo = [];
      this.dialog_view = "insert";
    },
    buscararticulo(search) {
      let _this = this;
      let data = {};
      //if(kind == 'descripcion') data['descripcion'] = search;
      data["busqueda"] = search;
      
      this.api("/peticion/articulosfiltro/", data, false).then(
        resp => {
          if (resp.success == 1 && resp.datos != null) {
            //Todo Ok
            _this.results_articulo = resp.datos;
          }
          else if(resp.success == 2){_this.toasted(resp.msg,"error","error");}

        },
        err => {
          //this.vista = "pedidoerror";
          console.log(err);
        }
      );
    },

    // editItem(item) {
    //   this.editedIndex = this._desserts.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialog = true;
    // },

    // deleteItem(item) {
    //   //const index = this._desserts.indexOf(item);
    //   //confirm("Are you sure you want to delete this item?") && this._desserts.splice(index, 1);
    // },

    // close() {
    //   this.dialog = false;
    //   setTimeout(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   }, 300);
    // },

    // save() {
    //   if (this.editedIndex > -1) {
    //     Object.assign(this._desserts[this.editedIndex], this.editedItem);
    //   } else {
    //     this._desserts.push(this.editedItem);
    //   }
    //   this.close();
    // }
  }
};
</script>
