<template>
    <v-container>
      <v-card-title style="padding: 10px 0px;">
        <v-spacer class="titulo-combi-search">PREALBARANES</v-spacer> 
        <v-text-field  v-if="desserts" style="max-width:500px;width: 100%;"
          v-model="search"
          append-icon="search"
          label="Buscar..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-toolbar flat color="white">
        <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
          <template >
            <v-btn @click="dialogcrearprealbaran=true" color="primary" dark class="mb-2">CREAR PREALABRÁN</v-btn>
            <v-dialog v-model="dialogcrearprealbaran" persistent max-width="500px">
              <v-card style="background:#ffffff;">
                <v-card-title>
                  <span class="headline">Crear nuevo prealbarán?</span>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#555555" text @click="dialogcrearprealbaran=false">No</v-btn>
                  <v-btn color="#555555" text @click="crearprealbaran()">Si</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
      </v-toolbar>

      <v-data-table
        v-if="desserts"
        :headers="headers"
        :items="desserts"
        item-key="idprealbaran"
        :items-per-page="20"
        :search="search"
        :footer-props="{'items-per-page-options': [100,1000]}"
        hide-default-footer
        sort-by="fecha"
        class="tabla_escritorio"
      >
       <template v-slot:item.fecha="{ item }">
        <div style="line-height: 14px;margin: 4px 0px" >{{ formatDate( item.fecha ) }}</div>
      </template>

        <template v-slot:item.observaciones="{ item }">
        <div style="line-height: 14px;margin: 4px 0px" >{{ item.observaciones }}</div>
      </template>

        <template v-slot:item.action="{ item }">
          <v-icon small @click="viewItem(item)">visibility</v-icon>
        </template>
      </v-data-table>

        <v-select
         v-if="desserts"
          class="tabla_lineas_movil"
          v-model="filter"
          :items="filters"
          item-value="value"
          v-bind:label="filter === '' ? filters[0].text : ''"
        ></v-select>



        <v-container v-if="prealbaranesfilter" class="tabla_lineas_movil" grid-list-md>
            <v-layout wrap v-for="item in prealbaranesfilter" :key="item.idprealbaran" @click="viewItem(item)">
              <v-flex xs12>
                <div>
                    <v-chip class="mb-3 mr-3"  label color="#666666" text-color="white" > 
                      <v-icon left>label</v-icon>{{item.codigo}}
                    </v-chip>             
                    <v-chip class="mb-3 mr-3" label>
                        <v-icon left>av_timer</v-icon>{{ formatDate(item.fecha) }}
                    </v-chip>
                  <!-- <p>{{item.estado}}</p> -->
                </div>
                <div>
                    <div class="text">         
                      <div v-if="item.observaciones">
                        {{item.observaciones}}
                      </div>
                    </div>
                <!-- <v-icon v-if="!item.firma">check_box_outline_blank</v-icon>
                <v-icon v-if="item.firma">check_box</v-icon> -->
                </div>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-container>

        <v-container v-else>
          <v-card>
            <v-card-text>
              NO HAY PREALBARANES QUE MOSTRAR
            </v-card-text>
          </v-card>
        </v-container>

    </v-container>
</template>

<script>
import { gw } from "@/gw.js";
//import axios from 'axios'

export default {
  mixins: [gw],
  name: "PreAlbaranes",
  props: ["desserts"],
  data: () => ({
    search: "",
    dialogcrearprealbaran: "",
    //dialog: false,
    headers: [
      { text: "Código", value: "codigo", sortable:true },
      { text: "Fecha", value: "fecha", sortable:true },
      { text: "Estado", value: "estado" , sortable:true},
      { text: "Observaciones", value: "observaciones" , sortable:true},
      //{ text: "Firmado", value: "firmadocli", sortable:true },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    filters:[
      { text: "Código", value: "codigo" },
      { text: "Fecha", value: "fecha" },
      { text: "Estado", value: "estado" }
    ],
    filter:'',
    //desserts: [],
    //editedIndex: -1,
    /* editedItem: {
      r: "",
      d: "",
      cp: "",
      obs: ""
    }, */
    /* defaultItem: {
      r: "",
      d: "",
      cp: "",
      obs: ""
    } */
  }),
   computed: {
   prealbaranesfilter() {
     let _this = this;
     if(this.desserts!=undefined && this.desserts.length>0 && this.desserts[0]['search']==undefined){
        this.desserts.forEach(function(element) {
            element['search'] = Object.values(element).toString().toLowerCase();
        });
      }
             if(this.search.length>2){
              return this.desserts.filter(o => {
                if(o['search'].search(this.search.toLowerCase()) != -1){
                  this.closekeyboard();
                  return this.desserts;
                }
              })
             }else{
               this.closekeyboard();
                return this.desserts;
              }
        }
  }, 
   watch: {
    filter(val) {
      this.filter = val;
      this.filterlist();
    }
  }, 
  // created() {
  //   console.log(this.desserts);
    
  // }, 
  mounted() {
     /* let num=0;
                this.desserts.forEach(function(element) {
                    this.desserts[num]['search'] = Object.values(element).toString().toLowerCase();
                    num++;
                }); */
  },
  methods: {
    /* editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      //const index = this.desserts.indexOf(item);
      //confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    }, */
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
    filterlist(){
      if (this.desserts != undefined) {
        let canpo = this.filter;
        return this.desserts.sort((a, b) => (b[canpo] > a[canpo]) ? -1 : 1)
      } 
      //this.prealbaranesfilter()
    },
    viewItem(item){
      //this.to("/prealbaran/"+item.codigo);
      this.$router.push("/prealbaran/ver/"+item.codigo);
    },
    crearprealbaran(item) {
      this.to("/prealbaran/crear/"+this._routerRoot._route.params.codorden);
    },

   /*  close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    }, */

   /*  save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    } */
  }
};
</script>
