<template>
    <v-container>

      <v-card-title>
        <v-spacer class="titulo-combi-search">ALBARANES</v-spacer>
        <v-text-field  v-if="desserts" style="max-width:500px;width: 100%;"
          v-model="search"
          append-icon="search"
          label="Buscar..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-if="desserts"
        :headers="headers"
        :items="desserts"
        item-key="idalbaran"
        :items-per-page="20"
        :search="search"
        :footer-props="{'items-per-page-options': [100,1000]}"
        sort-by="fecha"
        class="tabla_escritorio"
      >
       <template v-slot:item.fecha="{ item }">
        <div style="line-height: 14px;margin: 4px 0px" >{{ formatDate( item.fecha ) }}</div>
      </template>

        <template v-slot:item.observaciones="{ item }">
        <div style="line-height: 14px;margin: 4px 0px" >{{ item.observaciones }}</div>
      </template>

        <template v-slot:item.action="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon> -->
          <v-icon small class="mr-2" @click="viewItem(item)">visibility</v-icon>
        </template>
        <!-- <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>-->
      </v-data-table>

        <v-select
           v-if="desserts"
          class="tabla_lineas_movil"
          :items="filters"
          item-value="value"
          v-bind:label="filter === '' ? filters[0].text : ''"
          v-model="filter"
        ></v-select>

       <v-container v-if="prealbaranesfilter" class="tabla_lineas_movil" grid-list-md>
            <v-layout wrap v-for="item in prealbaranesfilter" :key="item.idalbaran" @click="viewItem(item)">
              <v-flex xs12>
                <div>
                    <v-chip class="mb-3 mr-3" label  color="#666666" text-color="white"> 
                      <v-icon left>label</v-icon>{{item.codigo}}
                    </v-chip>

                    

                    <v-chip class="mb-3 mr-3" label>
                        <v-icon left>av_timer</v-icon>{{ formatDate(item.fecha) }}
                    </v-chip>

                    <v-chip class="mb-3 mr-3" label  v-if="item.firmadocli=='t'">
                        <v-icon left>create</v-icon> Firmado
                    </v-chip>

                  <!-- <h3>{{item.estado}}</h3> -->
                </div>
                <div>
                    <div class="text mt2">         
                      <div v-if="item.observaciones">
                        {{item.observaciones}}
                      </div>
                    </div>
                <!-- <v-icon v-if="!item.firma">check_box_outline_blank</v-icon>
                <v-icon v-if="item.firma">check_box</v-icon> -->
                </div>
                <div>
                  
                </div>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-container>
            <v-container v-else>
      <v-card>
        <v-card-text>
          NO HAY ALBARANES QUE MOSTRAR
        </v-card-text>
      </v-card>
    </v-container>

    </v-container>
</template>

<script>
import { gw } from "@/gw.js";
//import axios from 'axios'

export default {
  mixins: [gw],
  name: "Albaranes",
  props: ["desserts"],
  data: () => ({
    search: "",
    headers: [
      { text: "Código", value: "codigo" },
      { text: "Fecha", value: "fecha" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Firmado", value: "firmadocli", sortable:true },
      // { text: "Total", value: "total" },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    filters:[
      { text: "Código", value: "codigo" },
      { text: "Fecha", value: "fecha" },
      { text: "Firmado", value: "firmadocli" }
    ],
    filter:''
    //desserts: [],
    //editedIndex: -1,
    /* editedItem: {
      r: "",
      d: "",
      cp: "",
      obs: ""
    }, */
    /* defaultItem: {
      r: "",
      d: "",
      cp: "",
      obs: ""
    } */
  }),
  computed: {
    prealbaranesfilter() {
      let _this = this;
      if(this.desserts!=undefined && this.desserts.length>0 && this.desserts[0]['search']==undefined){
        let num=0;
        this.desserts.forEach(function(element) {
            _this.desserts[num]['search'] = Object.values(element).toString().toLowerCase();
            num++;
        });
      }
      if(this.search.length>2){
        return this.desserts.filter(o => {
          if(o['search'].search(this.search.toLowerCase()) != -1){
            this.closekeyboard();
            return this.desserts;
          }
        })
        }else{
          this.closekeyboard();
          return this.desserts;
        }
      }
  }, 
   watch: {
    filter(val) {
      this.filter = val;
      this.filterlist();
    }
  }, 
  /* created() {
    //this.initialize();
  }, */
  methods: {
    filterlist(){
      if (this.desserts != undefined) {
        let canpo = this.filter;
        return this.desserts.sort((a, b) => (b[canpo] > a[canpo]) ? -1 : 1)
      } 
    },
    /* editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_albaran_email = true;
    },

    deleteItem(item) {
      //const index = this.desserts.indexOf(item);
      //confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    }, */
    viewItem(item){
      this.to("/albaran/"+item.codigo);
    },
    enviaremail(item){
      this.to("/albaran/"+item.codigo);
    },
   /*  close() {
      this.dialog_albaran_email = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    }, */

   /*  save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    } */
  }
};
</script>
