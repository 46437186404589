<template>
    <v-container>

      <v-card-title>
        <v-spacer class="titulo-combi-search">DOCUMENTOS ADJUNTOS</v-spacer>
        <v-text-field  v-if="desserts" style="max-width:500px;width: 100%;"
          v-model="search"
          append-icon="search"
          label="Buscar..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        v-if="desserts"
        :headers="headers"
        :items="desserts"
        item-key="iddocumento"
        :items-per-page="20"
        :search="search"
        :footer-props="{'items-per-page-options': [100,1000]}"
        sort-by="fechacompleta"
        class="tabla_escritorio"
      >

        <template v-slot:item.descripcion="{ item }">
          <div style="line-height: 14px;margin: 4px 0px" >{{ item.descripcion }}</div>
        </template>

        <template v-slot:item.fecha="{ item }">
          <div style="line-height: 14px;margin: 4px 0px" >{{ formatDate( item.fecha ) }} {{item.hora}}</div>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon> -->
          <v-icon small class="mr-2" @click="viewItem(item)">visibility</v-icon>
        </template>

        <!-- <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>-->
      </v-data-table>




      <v-select
          v-if="desserts"
        class="tabla_lineas_movil"
        :items="filters"
        item-value="value"
        v-bind:label="filter === '' ? filters[0].text : ''"
        v-model="filter"
      ></v-select>

          <v-container v-if="adjuntosfilter" class="tabla_lineas_movil" grid-list-md>
            <v-layout wrap v-for="item in adjuntosfilter" :key="item.iddocumento" @click="viewItem(item)">
              <v-flex xs12>
                <div>
                    <v-chip class="mb-3 mr-3" label  color="#666666" text-color="white"> 
                      <v-icon left>label</v-icon>{{item.codigo}}
                    </v-chip>

                    

                    <v-chip class="mb-3 mr-3" label>
                        <v-icon left>av_timer</v-icon>{{ formatDate(item.fecha) }}  {{item.hora}}
                    </v-chip>

                    <v-chip class="mb-3 mr-3" label>
                        <v-icon left>attach_file</v-icon>{{ item.extension }}
                    </v-chip>


                </div>
                <div>
                    <div class="text mt2">         
                      <div v-if="item.descripcion">
                        {{item.descripcion}}
                      </div>
                    </div>
                <!-- <v-icon v-if="!item.firma">check_box_outline_blank</v-icon>
                <v-icon v-if="item.firma">check_box</v-icon> -->
                </div>
                <div>
                  
                </div>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-container>





      <v-container v-else>
      <v-card>
        <v-card-text>
          NO HAY ADJUNTOS QUE MOSTRAR
        </v-card-text>
      </v-card>
    </v-container>


     <v-toolbar flat v-if="view_adjunto">
      <v-dialog v-model="view_adjunto" persistent>
        <v-card style="background:#ffffff;">
          <v-card-title>
            <span class="headline">{{adjunto.descripcion}}</span>
          </v-card-title>
          
          <v-card-text>
            <embed width="100%" height="100%" name="plugin" v-bind:src="'data:application/pdf;base64,'+encodeURI(adjunto.base64)">
            
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#555555" text @click="view_adjunto=false;">CERRAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>


  </v-container>
</template>

<script>
import { gw } from "@/gw.js";
//import axios from 'axios'

//        $sql ="SELECT iddocumento, codigo,descripcion,fecha,hora,idusuario,rutadocumento,nombredocumento,extension from documentos 


export default {
  mixins: [gw],
  name: "Adjuntos",
  props: ["desserts"],
  data: () => ({
    search: "",
    view_adjunto: false,
    adjunto: [],
    headers: [
      { text: "Código", value: "codigo" },
      { text: "descripcion", value: "descripcion"},
      { text: 'Tipo', value: 'extension'},
      { text: "Fecha", value: "fecha" },
      { text: 'Actions', value: 'action', sortable: false }

    ],
    filters:[
       { text: "Código", value: "codigo" },
       { text: "Fecha", value: "fecha" },
       { text: "Tipo", value: "extension" }

    ],
    filter:''
    //desserts: [],
    //editedIndex: -1,
    /* editedItem: {
      r: "",
      d: "",
      cp: "",
      obs: ""
    }, */
    /* defaultItem: {
      r: "",
      d: "",
      cp: "",
      obs: ""
    } */
  }),
  computed: {
    adjuntosfilter() {
      let _this = this;
      if(this.desserts!=undefined && this.desserts.length>0 && this.desserts[0]['search']==undefined){
        let num=0;
        this.desserts.forEach(function(element) {
            _this.desserts[num]['search'] = Object.values(element).toString().toLowerCase();
            num++;
        });
      }
      if(this.search.length>2){
        return this.desserts.filter(o => {
          if(o['search'].search(this.search.toLowerCase()) != -1){
            this.closekeyboard();
            return this.desserts;
          }
        })
        }else{
          this.closekeyboard();
          return this.desserts;
        }
      }
  }, 
   watch: {
    filter(val) {
      this.filter = val;
      this.filterlist();
    }
  }, 
  /* created() {
    //this.initialize();
  }, */
  methods: {
    filterlist(){
      if (this.desserts != undefined) {
        let canpo = this.filter;
        return this.desserts.sort((a, b) => (b[canpo] > a[canpo]) ? -1 : 1)
      } 
    },
    /* editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog_albaran_email = true;
    },

    deleteItem(item) {
      //const index = this.desserts.indexOf(item);
      //confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    }, */
    viewItem(item){

       let data = {
        codorden: this._routerRoot._route.params.codorden,
        codigo: item.codigo,
        action:'obteneradjunto'
      };

      let _this = this;
      this.api("/peticion/ordentrabajo", data, true).then(
        resp => {
          if (resp.success == 1){
            _this.adjunto = resp.datos;

            _this.generaDescargablePdf(_this.adjunto.base64,_this.adjunto.descripcion+'.'+_this.adjunto.extension);
            //window.open("data:application/pdf," + encodeURI(this.adjunto.base64)); 
            //this.view_adjunto = true;
          }
          else if (resp.success == 2){
            _this.toasted(
              resp.msg,
              "error",
              "error"
            );
          }
          else{
            _this.toasted(
              "Error al obtener el adjunto",
              "error",
              "error"
            );
          }
        },
        err => {
          console.log(err);
        }
      );
    },
    generaDescargablePdf(data, name){
    var arrBuffer = this.base64ToArrayBuffer(data);

    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([arrBuffer]);

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    // For other browsers: 
    // Create a link pointing to the ObjectURL containing the blob.
    var data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a');
    document.body.appendChild(link); //required in FF, optional for Chrome
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  },
  base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
   /*  close() {
      this.dialog_albaran_email = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    }, */

   /*  save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    } */
  }
};
</script>
